<script setup lang="ts">
interface Props {
  shade?: string
  href?: string
}

const props = withDefaults(defineProps<Props>(), {
  shade: 'light'
})

const href = computed(() => {
  if (props.href) return props.href
  return '/sign_up'
})

</script>

<template>
  <NuxtLinkLocale :class="props.shade" class="try button icon-text" :href="href">
    {{ $t('common.try_for_free') }}
    <Icon name="circled-play" width="24px" />
  </NuxtLinkLocale>
</template>

<style scoped lang="scss">
// Custom buttons
.try.button {
  @include button-colors($color-black, $color-white);

  .icon {
    margin-left: 0.5rem;
    margin-right: 0;
  }

  :deep(svg) {
    fill: $color-black;
  }

  &.medium {
    @include button-colors($color-white, hsl(0, 0%, 25%));

    &:hover {
      background-color: color.adjust($color-sprout, $lightness: -12.5%);
    }

    :deep(svg) {
      fill: $color-white;
    }
  }

  &.dark {
    @include button-colors($color-white, $color-black);


    :deep(svg) {
      fill: $color-white;
    }
  }

  &.green {
    @include button-colors($color-white, $color-sprout);

    :deep(svg) {
      fill: $color-white;
    }
  }
}
</style>
